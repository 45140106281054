import React from "react";

function Hero() {
  return (
    // <section id="home" className="relative overflow-hidden">
    <section
      id="home"
      className="relative bg-cover bg-center"
      style={{ backgroundImage: "url('bg-house.png')" }}
    >
      <div class="absolute inset-0 bg-black bg-opacity-60"></div>

      {/* <div className="absolute top-0 left-0 w-full h-full">
        <iframe
          title="SipBau Video"
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/50Lf9OJw0Pw?autoplay=1&mute=1&controls=0&loop=1&playlist=50Lf9OJw0Pw"
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
          className="object-cover"
        ></iframe>
      </div> */}
      <div className="text-white text-center p-12 relative z-10">
        <div className="max-w-2xl mx-auto">
          {" "}
          {/* Adjust the max width as needed */}
          <h1 className="text-4xl font-bold mb-3">
            Najkvalitetnije SIP panel kuce za vas savrsen dom!
          </h1>
          <p className="mb-6">
            Izgradite svoj dom iz snova s nasim visokokvalitetnim SIP panelima.
            Montaza je brza, materijal je izdriljiv, a kuca energetski efikasna
            i ekoloski prihvatljiva. Odaberite sigurnost i udobnost za svoju
            porodicu - izaberite nase SIP panel kuce!
          </p>
          {/* <a
          href="#portfolio"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Explore Our Projects
        </a> */}
        </div>
      </div>
    </section>
  );
}

export default Hero;
