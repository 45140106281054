import React from "react";

function Contact() {
  return (
    <section id="contact" className="bg-gray-100 py-8">
      <div className="container mx-auto px-4">
        <h2 className="text-center text-3xl font-bold mb-6">Kontakt</h2>
        <div className="max-w-md mx-auto bg-white shadow-lg rounded-lg p-6">
          <form
            action="https://formkeep.com/f/7d55c9a37371"
            method="post"
            className="space-y-4"
          >
            <div>
              <label htmlFor="name" className="text-sm font-medium">
                Ime
              </label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Ime"
                className="mt-1 w-full p-2 border border-gray-300 rounded"
                required
              />
            </div>
            <div>
              <label htmlFor="email" className="text-sm font-medium">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                className="mt-1 w-full p-2 border border-gray-300 rounded"
                required
              />
            </div>
            <div>
              <label htmlFor="message" className="text-sm font-medium">
                Poruka
              </label>
              <textarea
                id="message"
                name="message"
                placeholder="Poruka"
                className="mt-1 w-full p-2 border border-gray-300 rounded h-32"
                required
              ></textarea>
            </div>
            <button
              type="submit"
              className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Posalji poruku
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}

export default Contact;
