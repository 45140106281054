import React from "react";
import Header from "./components/Header";
import Hero from "./components/Hero";
// import About from "./components/About";
// import Services from "./components/Services";
// import Portfolio from "./components/Portfolio";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Project from "./components/Project";

function App() {
  return (
    <div>
      <Header />
      <Hero />
      <Project />
      {/* <About />
      <Services />
      <Portfolio /> */}
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
