import React from "react";

function Project() {
  return (
    <section className="py-8 bg-blue-100">
      <div className="container mx-auto px-4">
        <div className="flex justify-center">
          <div className="w-full" style={{ maxWidth: "576px" }}>
            {/* Aspect ratio wrapper */}
            <div style={{ position: "relative", paddingTop: "56.25%" }}>
              {" "}
              {/* 16:9 Aspect Ratio */}
              <iframe
                title="Video Projekta"
                src="https://sipbaustorage.s3.eu-west-1.amazonaws.com/project-1.mp4"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Project;
