import React from "react";

function Footer() {
  return (
    <footer className="bg-gray-800 text-white p-4">
      <div className="container mx-auto flex justify-center items-center h-16">
        <div className="text-center">
          <p>© 2023 SipBau.</p>
          <p>Nemanjina 4, Savski Venac 11000 Beograd</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
